import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { SERVICE_CONTACT_TYPES } from "../../../AppConst"

/**
 * Material
 */
import { ContactInputGroup } from "../common/ContactInputGroup"
import { ContactInputCheckboxes } from "../common/ContactInputCheckboxes"
import { ContactInputText } from "../common/ContactInputText"
import { ContactInputTextarea } from "../common/ContactInputTextarea"
import { ContactInputEmail } from "../common/ContactInputEmail"
import { ContactInputLink } from "../common/ContactInputLink"
import { ContactInputFile } from "../common/ContactInputFile"
import { AgreedPolicy } from "../common/AgreedPolicy"
import { servicesFormValues, useServicesFormContext } from "../../../utlis/FormValues"
import { validRequired, invalidRequired } from "../../../utlis/check"
import { contact } from "../../../data/contact"
import { ContactFormConfirmButton } from "../common/ContactFormConfirmButton"

/**
 * Element & Styles
 */
const Form = styled.form`
`

const FormNode = styled.div`
  display: flex;
  flex-direction: column;
  gap: 96px;
`

type FormData = servicesFormValues
type Errors = {
  types: boolean
  content: boolean
  name: boolean
  email: boolean
}

/**
 * Component
 */
export const ContactServiceForm: React.VFC = () => {
  const [formData, setFormData] = useState<FormData>({
    types: [],
    content: "",
    company: "",
    name: "",
    email: "",
    webSites: [],
    files: [],
  })
  const [isErrors, setIsErrors] = useState<Errors>({
    types: false,
    content: false,
    name: false,
    email: false,
  })
  const [checked, setChecked] = useState(false)

  // バリデーション
  const validate = (values: FormData) => {
    setIsErrors(_errors => ({ ..._errors, types: invalidRequired(values.types) }))
    setIsErrors(_errors => ({ ..._errors, content: invalidRequired(values.content) }))
    setIsErrors(_errors => ({ ..._errors, name: invalidRequired(values.name) }))
    setIsErrors(_errors => ({ ..._errors, email: invalidRequired(values.email) }))
  }

  const isValid = (values: FormData) => {
    validate(values)
    return (
      validRequired(values.types) &&
      validRequired(values.content) &&
      validRequired(values.name) &&
      validRequired(values.email)
    )
  }

  const { state, setState } = useServicesFormContext()

  useEffect(() => {
    setFormData({ ...state })
  }, [])

  const updateFormData = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value })
    setIsErrors(_errors => ({ ..._errors, [name]: false }))
  }

  return (
    <Form
      onSubmit={event => {
        event.preventDefault()

        // エラーがある場合、画面遷移しない
        if (!isValid(formData)) return

        // useContextの設定
        setState({ ...formData })

        navigate("./confirm")
      }}
    >
      <FormNode>
        <ContactInputGroup
          label={contact.service.types.title}
          isRequired={contact.service.types.required}
          hasError={isErrors.types}>
          <ContactInputCheckboxes
            values={SERVICE_CONTACT_TYPES}
            setter={(value) => updateFormData(value, "types")}
            checkedValues={formData.types}
            isRequired={contact.service.types.required}
            hasError={isErrors.types}
          />
        </ContactInputGroup>

        <ContactInputGroup
          label={contact.service.content.title}
          isRequired={contact.service.content.required}
          hasError={isErrors.content}>
          <ContactInputTextarea
            value={formData.content}
            setter={(value) => updateFormData(value, "content")}
            isRequired={contact.service.content.required}
          />
        </ContactInputGroup>

        <ContactInputGroup
          label={contact.service.company.title}
          isRequired={contact.service.company.required}>
          <ContactInputText
            value={formData.company}
            setter={(value) => updateFormData(value, "company")}
            isRequired={contact.service.company.required}
            placeholder={"例）ネクストライブ株式会社"}
          />
        </ContactInputGroup>

        <ContactInputGroup
          label={contact.service.name.title}
          isRequired={contact.service.name.required}
          hasError={isErrors.name}>
          <ContactInputText
            value={formData.name}
            setter={(value) => updateFormData(value, "name")}
            isRequired={contact.service.name.required}
            placeholder={"例）大阪　太郎"}
          />
        </ContactInputGroup>

        <ContactInputGroup
          label={contact.service.email.title}
          isRequired={contact.service.email.required}
          hasError={isErrors.email}>
          <ContactInputEmail
            value={formData.email}
            setter={(value) => updateFormData(value, "email")}
            isRequired={contact.service.email.required}
            placeholder={"例）example@nextribe.co.jp"}
          />
        </ContactInputGroup>

        <ContactInputGroup
          label={contact.service.webSites.title}
          isRequired={contact.service.webSites.required}>
          <ContactInputLink
            links={formData.webSites}
            setter={(value) => updateFormData(value, "webSites")}
          />
        </ContactInputGroup>

        <ContactInputGroup
          label={contact.service.files.title}
          isRequired={contact.service.files.required}
          message={contact.service.files.annotaion}
          >
          <ContactInputFile
            id={"file"}
            files={formData.files}
            setter={(value) => updateFormData(value, "files")}
          />
        </ContactInputGroup>

        <AgreedPolicy checked={checked} setter={setChecked} />

        <ContactFormConfirmButton isDisabled={!checked} />
      </FormNode>
    </Form>
  )
}
